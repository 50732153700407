




























import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { MkrTextfield, MkrSteppedProgress } from '@livementor/mikado_reborn/src/index-core'
import { extend } from 'vee-validate'

import { zxcvbn, zxcvbnOptions, ZxcvbnResult } from '@zxcvbn-ts/core'
import { OptionsType } from '@zxcvbn-ts/core/dist/types'

@Component({
  components: {
    MkrTextfield,
    MkrSteppedProgress,
  },
})
export default class extends Vue {
  @Prop({ type: Boolean, default: false })
  showPasswordStrength!: boolean

  @Prop({ type: Boolean, default: false })
  error!: boolean

  passwordValue: string | null = null

  async created() {
    const options = await this.loadZxcvbnOptions()
    zxcvbnOptions.setOptions(options)

    extend('passwordScore', {
      validate: (_value, args) => {
        const minScore = (args as number[])[0] as number
        return this.passwordScore >= minScore
      },
      message: 'Le mot de passe est trop faible, consultez les conseils de sécurité.',
    })
  }

  async loadZxcvbnOptions(): Promise<OptionsType> {
    const { dictionary: commonDictionary } = (
      await import(/* webpackChunkName: "zxcvbn-commom-package" */ '@zxcvbn-ts/language-common')
    ).default
    const { dictionary, translations } = (
      await import(/* webpackChunkName: "zxcvbn-fr-package" */ '@zxcvbn-ts/language-fr')
    ).default

    return {
      dictionary: {
        ...commonDictionary,
        ...dictionary,
      },
      translations,
    }
  }

  setPassword(value: string) {
    this.$emit('input', value)

    this.passwordValue = value
  }

  get passwordScore() {
    if (!this.showPasswordStrength) {
      return 0
    }
    const { score } = zxcvbn(this.passwordValue || '') as ZxcvbnResult

    return score
  }

  get passwordStrength(): String {
    switch (this.passwordScore) {
      case 0:
      case 1:
        return 'Faible'
      case 2:
        return 'Moyen'
      case 3:
      case 4:
        return 'Fort'
      default:
        return 'Faible'
    }
  }

  get strengthColor(): string {
    switch (this.passwordScore) {
      case 0:
      case 1:
        return 'danger'
      case 2:
        return 'primary'
      case 3:
      case 4:
        return 'secondary'
      default:
        return 'danger'
    }
  }

  emitChange(event: Event): void {
    this.$emit('change', event)
  }

  emitBlur(event: Event): void {
    this.$emit('blur', event)
  }
}
