
















import { MkrOutlinedButton } from '@livementor/mikado_reborn/src/components'
import { defineComponent, onBeforeMount, ref, useContext } from '@nuxtjs/composition-api'
import { ScriptLoader } from '@/utils/script-loader'

export default defineComponent({
  components: { MkrOutlinedButton },
  setup() {
    const { $config } = useContext()
    const client_id = ref($config.GOOGLE_CLIENT_ID)
    const client = ref<any>(null)

    const initGSIApi = () => {
      const redirect_uri = window.location.origin + '/callback'
      // @ts-ignore
      client.value = window.google.accounts.oauth2.initCodeClient({
        client_id: client_id.value,
        scope: 'openid email profile',
        ux_mode: 'redirect',
        redirect_uri,
      })
    }

    const loadGSIApi = async () => {
      try {
        const scriptLoader = new ScriptLoader({
          identifier: 'google-accounts',
          src: 'https://accounts.google.com/gsi/client',
          async: false,
        })

        await scriptLoader.load()

        initGSIApi()
      } catch (error) {
        throw new Error('Google API not loaded: ' + error)
      }
    }

    onBeforeMount(loadGSIApi)
    return {
      client,
    }
  },
})
