

























import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ValidationProvider from '@/components/global/mkr/ValidationProvider.vue'

@Component({
  components: {
    ValidationProvider,
  },
})
export default class FormItem extends Vue {
  @Prop({ required: false, default: undefined })
  rules?: Record<string, string> | string

  @Prop({ required: true, type: String })
  name!: string

  @Prop({ type: String, required: false })
  vid?: string

  @Prop({ required: false, default: undefined })
  label?: string

  @Prop({ required: false, default: undefined })
  description?: string

  @Prop({ required: false, default: 'eager' })
  mode?: string
}
