











import { ValidationObserver } from 'vee-validate'

import { Component, Prop } from 'nuxt-property-decorator'
import { Vue } from 'vue-property-decorator'

@Component({
  components: {
    ValidationObserver,
  },
})
export default class Form extends Vue {
  @Prop({ type: Boolean, default: false })
  noSpacing!: boolean
}
