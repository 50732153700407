











































































import {
  MkrTextButton,
  MkrTextfield,
  MkrContainedButton,
} from '@livementor/mikado_reborn/src/index-core'
import { extend } from 'vee-validate'
import { defineComponent, onBeforeMount, ref, useMeta } from '@nuxtjs/composition-api'
import LMForm from '@/components/global/form/Form.vue'
import FormItem from '@/components/global/form/FormItem.vue'
import PasswordField from '@/components/global/PasswordField.vue'
import ConnectWithGoogleButton from '@/components/global/ConnectWithGoogleButton.vue'

export default defineComponent({
  components: {
    MkrContainedButton,
    MkrTextButton,
    MkrTextfield,
    PasswordField,
    LMForm,
    FormItem,
    ConnectWithGoogleButton,
  },

  layout: 'login',

  setup(_props, { root: { $store, $i18n, $auth, $notify, $cookies } }) {
    useMeta({
      title: $i18n.t('login.meta_title') as string,
    })

    const email = ref<string>()
    const password = ref<string>()
    const error = ref<string>()
    const isLoading = ref<boolean>(false)

    onBeforeMount(() => {
      $store.dispatch('RESET_SHOULD_LOGOUT')
      $cookies.remove('shouldNotSignTrainingDiploma')
      extend('invalidCredentials', {
        validate: (_value, _args) => {
          return error.value === ''
        },
        params: ['error'],
        message: '{error}',
      })
    })

    const loginLocal = async () => {
      try {
        await $auth.login({
          email: email.value || '',
          password: password.value || '',
        })
      } catch (err: any) {
        error.value = err.response.data.error.message
      } finally {
        isLoading.value = false
      }
    }

    const onSubmit = async ({
      validate,
    }: {
      reset: () => void
      validate: () => Promise<boolean>
    }) => {
      const isValid = await validate()
      if (!isValid) {
        return
      }

      try {
        isLoading.value = true
        await loginLocal()
      } catch (e: any) {
        $notify({
          message: $i18n.t(`notifications.error.${e.response.data.error.key}`),
          error: true,
        })
      } finally {
        isLoading.value = false
      }
    }

    return {
      onSubmit,
      email,
      password,
      error,
      isLoading,
    }
  },

  head: {},
})
